import SelecaoPadrao from './SelecaoPadrao';
import { entidadeList } from '../datasource/Entidade';

export default class SelecaoEntidade extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && ((item.nome_fantasia === "" || item.nome_fantasia === null) ? item.razao_social.trim() : item.nome_fantasia.trim());
}

SelecaoEntidade.defaultProps = {
    placeholder: "Pesquisar por nome/cnpj",
    aoAlterar: null,
    dataSource: entidadeList,
    displayExpr: displayExpr,
    columns: window.dx_Entidade_Colunas()
};