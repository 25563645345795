import React from 'react';
import App from '../../api/app';

export default class LabelSituacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        let situacao = "";

        if (this.state.detalhes) {
            if (this.state.detalhes && this.state.detalhes.rascunho === true) {
                situacao = "Rascunho";
            }
            else {
                situacao = window.ChamadoDescSituacaoHumano(this.state.detalhes.situacao, App.sessao.dados.tipo)
            }
        }
        
        return (
            <span className="label label-default">{situacao}</span>
        );
    }
}

LabelSituacao.defaultProps = {
    
};