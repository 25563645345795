import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const entidadeList = new DataSource({
    paging: {
        pageSize: 20
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: ['tipo', 'id'],
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
            route: "agenda_contatos/listar/", 
                data: {
                    // parametros_filtros: JSON.stringify(main.parametros_filtros),
                    dx_grid: true,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let entidadeId = key.id;
        let entidadeTipo = key.tipo;
        if (!entidadeId && !entidadeTipo) return false;

        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "agenda_contatos/listar/", 
                data: {
                    dx_grid: true,
                    entidade_id: entidadeId,
                    entidade_tipo: entidadeTipo
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});