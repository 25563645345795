import SelecaoPadrao from './SelecaoPadrao';
import { chamadoResponsavelList } from '../datasource/Chamado';
import { colunaUsuarioNome } from '../datasource/Usuario';

export default class SelecaoChamadoResponsavel extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: chamadoResponsavelList(this.props.chamadoId, this.props.entidadeTipo, this.props.entidadeId, this.props.motivoId),
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entidadeTipo !== this.props.entidadeTipo
            || prevProps.entidadeId !== this.props.entidadeId
            || prevProps.motivoId !== this.props.motivoId
            || prevProps.chamadoId !== this.props.chamadoId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && item.nome;
}

SelecaoChamadoResponsavel.defaultProps = {
    chamadoId: null,
    entidadeTipo: null,
    entidadeId: null,
    motivoId: null,
    aoAlterar: null,
    displayExpr: displayExpr,
    columns: [colunaUsuarioNome]
};