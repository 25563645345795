import React from 'react';

export default class InformativoPrazo extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState(this.processarDados(this.state.DataPrazo, this.state.CalendarioTrabalhoId, this.state.TipoGestaoTempo, this.state.HorasEstimadas, this.state.DiasEstimados));
        }, 60000); // 1 minuto
    }
    
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    propsToObjState() {
        const TipoGestaoTempo = this.props.TipoGestaoTempo ?? null;
        const HorasEstimadas = this.props.HorasEstimadas ?? null;
        const DataPrazo = this.props.DataPrazo ?? null;
        const CalendarioTrabalhoId = this.props.CalendarioTrabalhoId ?? null;
        const DiasEstimados = this.props.DiasEstimados ?? null;
        return {
            TipoGestaoTempo: TipoGestaoTempo,
            HorasEstimadas: HorasEstimadas,
            DiasEstimados: DiasEstimados,
            DataPrazo: DataPrazo,
            CalendarioTrabalhoId: CalendarioTrabalhoId,
            Concluido: this.props.Concluido ?? null,
            TituloDataPrazo: this.props.TituloDataPrazo ?? null,
            LabelExcedente: this.props.LabelExcedente ?? null,
            LabelRestante: this.props.LabelRestante ?? null,
            ExibirDataPrazo: this.props.ExibirDataPrazo ?? null,
            TituloDataPrazoClassName : this.props.TituloDataPrazoClassName ?? null,
            ExibirTituloDataPrazo: this.props.ExibirTituloDataPrazo ?? null,
            ExibirBarraProgresso: this.props.ExibirBarraProgresso ?? null,
            ExibirDiferencaHorasUteis: this.props.ExibirDiferencaHorasUteis ?? null,
            Alinhamento: this.props.Alinhamento ?? null,
            StyleWidth: this.props.StyleWidth ?? null,
            ...this.processarDados(DataPrazo, CalendarioTrabalhoId, TipoGestaoTempo, HorasEstimadas, DiasEstimados),
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.TipoGestaoTempo !== this.props.TipoGestaoTempo
            || prevProps.HorasEstimadas !== this.props.HorasEstimadas
            || prevProps.DataPrazo !== this.props.DataPrazo
            || prevProps.CalendarioTrabalhoId !== this.props.CalendarioTrabalhoId
            || prevProps.ExibirTituloDataPrazo !== this.props.ExibirTituloDataPrazo
            || prevProps.TituloDataPrazo !== this.props.TituloDataPrazo) {
            this.setState(this.propsToObjState());
        }
    }

    processarDados(DataPrazo, CalendarioTrabalhoId, TipoGestaoTempo, HorasEstimadas, DiasEstimados) {
        
        let data_agora = new Date();
        let data_agora_str = data_agora.now();
        
        let novo_state = {
            DiferencaHorasUteis: '00:00',
            DiferencaDias: 0,
            DiferencaHorasUteisSegundos: 0,
            PrazoEstourado: false,
            Percentual: 0,
            DataPrazoSemHora: 0,
        };

        
        // Verificou se está estourado
        if (data_agora_str > DataPrazo) {
            novo_state.PrazoEstourado = true;
        }

        // Calculo das horas/minutos restantes
        let tempo_restante = 0;
        
        if (novo_state.PrazoEstourado) {
            tempo_restante = window.fn_segundos_uteis(CalendarioTrabalhoId, DataPrazo, data_agora_str);
        }
        else {
            tempo_restante = window.fn_segundos_uteis(CalendarioTrabalhoId, data_agora_str, DataPrazo);
        }

        // format_time
        novo_state.DiferencaHorasUteis = window.fn_converter_segundos_para_horas(tempo_restante);
        
        // Calculo da diferença de dias
        novo_state.DataPrazoSemHora = window.date_create(DataPrazo);
        novo_state.DataPrazoSemHora.setMilliseconds(0);
        novo_state.DataPrazoSemHora.setSeconds(0);
        novo_state.DataPrazoSemHora.setMinutes(0);
        novo_state.DataPrazoSemHora.setHours(0);
        data_agora.setMilliseconds(0);
        data_agora.setSeconds(0);
        data_agora.setMinutes(0);
        data_agora.setHours(0);
        novo_state.DiferencaDias = window.date_diff(novo_state.DataPrazoSemHora, data_agora.getTime());

        // Calculo do percentual (utilizado para a barra de progresso)
        if (novo_state.PrazoEstourado) {
            novo_state.Percentual = 100;
        }
        else {
            if (+TipoGestaoTempo === window.GESTAO_TEMPO.HORAS_UTEIS && HorasEstimadas && HorasEstimadas !== '00:00:00') {
                let horas_previsao_retorno_segundos = window.fn_converter_horas_para_segundos(HorasEstimadas);
                novo_state.Percentual = (horas_previsao_retorno_segundos - tempo_restante) / horas_previsao_retorno_segundos * 100;
            }
            else if(+TipoGestaoTempo === window.GESTAO_TEMPO.DIAS_CORRIDOS && DataPrazo && DiasEstimados) {
                novo_state.Percentual = (+DiasEstimados - novo_state.DiferencaDias) / +DiasEstimados * 100;
            }
        }
        if (novo_state.Percentual > 100) novo_state.Percentual = 100;
        if (novo_state.Percentual < 0) novo_state.Percentual = 0;

        return novo_state;
    }

    renderTitulo() {
        if (this.state.ExibirTituloDataPrazo) {
            let className = this.state.TituloDataPrazoClassName + (this.state.Alinhamento === 'right' ? ' text-right' : '');
            return <div style={{whiteSpace: 'nowrap'}} className={className}>{this.state.TituloDataPrazo}</div>;
        }

        return null;
    }

    renderDataPrazo() {
        let prazo = null;
        
        if (+this.state.TipoGestaoTempo === window.GESTAO_TEMPO.DIAS_CORRIDOS) {
            prazo = <div style={{whiteSpace: 'nowrap'}} className={this.state.Alinhamento === 'right' ? 'text-right' : ''} title={this.state.TituloDataPrazo}>{this.state.DataPrazo ? this.state.DataPrazo.format_date() : ''}</div>;
        }
        else if(+this.state.TipoGestaoTempo === window.GESTAO_TEMPO.HORAS_UTEIS) {
            prazo = <div style={{whiteSpace: 'nowrap'}} className={this.state.Alinhamento === 'right' ? 'text-right' : ''} title={this.state.TituloDataPrazo}>{this.state.DataPrazo ? this.state.DataPrazo.format_date_time() : ''}</div>;
        }

        return <div>{prazo}</div>;
    }

    renderDiferencaHorasUteis() {
        let label = null;
        
        let labelClass = (this.state.PrazoEstourado || this.state.DiferencaDias === 0) ? 'text-danger' : 'text-default';
        let labelStyle = {font: 'inherit', width: '100%', whiteSpace: 'nowrap'};
        let divStyle = {fontWeight: 'bold'};

        divStyle.paddingLeft = '0px';
        divStyle.paddingRight = '0px';
        if (this.state.Alinhamento === 'left') {
            divStyle.paddingRight = '10px';
        }
        if (this.state.Alinhamento === 'right') {
            divStyle.paddingLeft = '10px';
        }

        switch (+this.state.TipoGestaoTempo) {

            case window.GESTAO_TEMPO.DIAS_CORRIDOS:
                label = <div style={labelStyle} className={"text " + labelClass} title={this.state.TituloDataPrazo}>{window.dia_contador_str(this.state.DiferencaDias, this.state.PrazoEstourado)}</div>;
                break;

            case window.GESTAO_TEMPO.HORAS_UTEIS:
            label = <div style={labelStyle} className={"text " + labelClass} title={this.state.PrazoEstourado ? this.state.LabelExcedente : this.state.LabelRestante}>{window.format_time(this.state.DiferencaHorasUteis)}</div>;
                break;
        
            default:
                break;
        }
        
        return <div style={divStyle}>{label}</div>;
    }

    renderBarraProgresso() {
        if(+this.state.TipoGestaoTempo === window.GESTAO_TEMPO.DIAS_CORRIDOS && this.state.DataPrazo && !this.state.DiasEstimados) {  
            return null;
        }

        let progressClass = this.state.PrazoEstourado ? 'progress-bar-danger' : 'progress-bar-default';
        return <div className="progress div_progress" style={{marginBottom: '0px', height: '12px'}}>
                    <div className={"progress-bar " + progressClass} style={{width: this.state.Percentual + '%'}}>
                        <div style={{ 
                                marginTop: -3, 
                                backgroundColor: 'transparent',
                                fontSize: 'xx-small'
                            }}>
                                {Math.round(this.state.Percentual)+'%'}
                            </div>
                    </div>
                </div>;
    }
        
    render() {
        if (this.state.Concluido) {
            return(
                <React.Fragment>
                    <span>-</span>
                </React.Fragment>
            );
        }
        else{

            let dataPrazo = null;
            if (this.state.ExibirDataPrazo) {
                dataPrazo = this.renderDataPrazo();
            }

            let diferencaHorasUteis = null;
            if (this.state.ExibirDiferencaHorasUteis) {
                diferencaHorasUteis = this.renderDiferencaHorasUteis();
            }

            let barraProgresso = null;
            if (this.state.ExibirBarraProgresso) {
                barraProgresso = this.renderBarraProgresso();
            }

            let flexFlow = null;
            if (this.state.Alinhamento === 'right') {
                flexFlow = 'row-reverse';
            }

            return (
                <div style={{width: this.state.StyleWidth}}>
                    {this.renderTitulo()}
                    <div style={{display: 'flex', flexWrap: 'wrap', flexFlow: flexFlow}}>
                        {diferencaHorasUteis}
                        {dataPrazo}
                    </div>
                    <div style={{/*display: 'flex'*/}}>
                        {barraProgresso}
                    </div>
                </div>
            );
        }   
    }
}

InformativoPrazo.defaultProps = {    
    TipoGestaoTempo: window.GESTAO_TEMPO.HORAS_UTEIS,
    HorasEstimadas: null,
    DiasEstimados: null,
    DataPrazo: '',
    CalendarioTrabalhoId: null,
    
    Alinhamento: 'left',
    StyleWidth: '160px',

    LabelExcedente: 'Atrasado',
    LabelRestante: 'Tempo Restante',
    TituloDataPrazo: 'Prazo',
    
    ExibirTituloDataPrazo: false,
    TituloDataPrazoClassName: "text-muted",
    ExibirDataPrazo: true,
    ExibirBarraProgresso: true,
    ExibirDiferencaHorasUteis: true,

    IntervalId: 0,

    Concluido: false
};