import React from 'react';

export default class CardTotalHorasTrabalhadas extends React.Component {

    render() {
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <label className="small text-muted">{this.props.label}</label>
                <div>{window.format_time(this.props.valor)}</div>
            </div>
        );
    }
}

CardTotalHorasTrabalhadas.defaultProps = {
    label: "Total horas trabalhadas",
};