import React from 'react';
import DataGridPadrao from '../../components/DataGridPadrao';
import View from '../../api/view';
import App from '../../api/app';
import { VerticalSpacer } from '../../components/Spacer';

export default class CardReferencias extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    propsToObjState() {
        let solicitacao_atividades = this.props.detalhes ? this.props.detalhes.solicitacao_atividades : [];
        
        return {
            detalhes: this.props.detalhes ?? null,
            oportunidade: this.props.detalhes ? this.props.detalhes.oportunidade : [],
            solicitacao: this.filtraSolicitacaoCabecalho(solicitacao_atividades),
            solicitacao_atividades: this.filtraSolicitacaoAtividade(solicitacao_atividades),
            projeto_atividades: this.props.detalhes ? this.props.detalhes.projeto_atividades : [],
            pdca_atividades: this.props.detalhes ? this.props.detalhes.pdca_atividades : [],
            tarefa_quadro_lista_cartao: this.props.detalhes ? this.props.detalhes.tarefa_quadro_lista_cartao : [],
        };
    }

    filtraSolicitacaoCabecalho(lista) {
        let novaLista = lista.filter((atividade) => {
            return +atividade.id === 0;
        });
        return novaLista;
    }

    filtraSolicitacaoAtividade(lista) {
        let novaLista = lista.filter((atividade) => {
            return +atividade.id > 0;
        });

        novaLista.map((atividade) => {
            atividade.atividade_descricao = atividade.solicitacao.versao_processo.elementos.find(
                (elemento) => +elemento.id === +atividade.versao_processo_atividade_id
            ).descricao;
            return atividade;
        });

        return novaLista;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        return(
            <div className="panel">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 style={{marginTop: "0px"}}>Referências</h4>
                        </div>
                        <div className="col-xs-12">

                            { this.state.oportunidade.length === 0
                                && this.state.solicitacao.length === 0
                                && this.state.solicitacao_atividades.length === 0
                                && this.state.projeto_atividades.length === 0
                                && this.state.pdca_atividades.length === 0
                                && this.state.tarefa_quadro_lista_cartao.length === 0 ?
                            <span>Não foram encontradas nenhuma referência para este chamado.</span>
                            : null }
    
                            { this.state.oportunidade.length > 0 ?
                            <React.Fragment>
                                <DataGridPadrao
                                    dataSource={this.state.oportunidade}
                                    remoteOperations={false}
                                    columns={[
                                        {
                                            dataField: "id",
                                            caption: "#",
                                            dataType: "number",
                                            alignment: "right",
                                            width: 40
                                        },
                                        {
                                            dataField: "funil_vendas_etapa_nome",
                                            caption: "Etapa do Funil",
                                            dataType: "string",
                                        },
                                        {
                                            dataField: "nome",
                                            caption: "Nome",
                                            dataType: "string",
                                        },
                                        {
                                            dataField: "data_abertura",
                                            caption: "Data de inclusão",
                                            dataType: "datetime"
                                        },
                                        {
                                            caption: "Tipo de Entidade",
                                            dataType: "string",
                                            calculateCellValue: function(data) {
                                                var txt_tipo_situacao = "";
                    
                                                switch(data.entidade_tipo) {
                                                    case 'C':
                                                        txt_tipo_situacao = "Cliente";
                                                        break;
                                                    case 'O':
                                                        txt_tipo_situacao = "Organização";
                                                        break;
                                                    default:
                                                        break;
                                                }
                    
                                                return txt_tipo_situacao;
                                            }
                                        },
                                        {
                                            dataField: "entidade_nome",
                                            caption: "Entidade",
                                            dataType: "string"
                                        },
                                        {
                                            dataField: "representante_nome",
                                            caption: "Representante",
                                            dataType: "string"
                                        },
                                        {
                                            dataField: "status",
                                            caption: "Status",
                                            dataType: "string"
                                        },
                                        {
                                            caption: "Valor do Negócio R$",
                                            dataType: "string",
                                            calculateCellValue: function(data) {
                                                return 'R$ '+data.valor_negocio.format_number(2, true);
                                            }
                                        },
                                        {
                                            dataField: 'situacao',
                                            caption: "Situação da Oportunidade",
                                            dataType: "string",
                                            calculateCellValue: function(data) {
                                                var txt_situacao_oportunidade = "";
                    
                                                switch(data.situacao) {
                                                    case 'G':
                                                        txt_situacao_oportunidade = 'Ganho';
                                                        break;
                                                    case 'P':
                                                        txt_situacao_oportunidade = 'Perdido';
                                                        break;
                                                    case 'A':
                                                        txt_situacao_oportunidade = 'Aberto';
                                                        break;
                                                    case 'AT':
                                                        txt_situacao_oportunidade = 'Atrasado';
                                                        break;
                                                    case 'E':
                                                        txt_situacao_oportunidade = 'Estagnado';
                                                        break;
                                                    default:
                                                        break;
                                                }
                    
                                                return txt_situacao_oportunidade;
                                            }
                                        },
                                        {
                                            dataField: 'situacao_ultimo_orcamento',
                                            caption: "Situação do Orçamento",
                                            dataType: "string",
                                            calculateCellValue: function(data) {
                                                var txt_situacao_orcamento = "";
                    
                                                if (data.situacao_ultimo_orcamento) {
                    
                                                    switch(data.situacao_ultimo_orcamento) {
                                                        case '0':
                                                            txt_situacao_orcamento = 'Orçamento Aguardando Aprovação';
                                                            break;
                                                        case '1':
                                                            txt_situacao_orcamento = 'Orçamento Aprovado';
                                                            break;
                                                        case '2':
                                                            txt_situacao_orcamento = 'Orçamento Reprovado';
                                                            break;
                                                        case '4':
                                                            txt_situacao_orcamento = 'Orçamento Aprovado / Aguardando E-Mail';
                                                            break;
                                                        default:
                                                            txt_situacao_orcamento = 'Orçamento Em Análise';
                                                            break;
                                                    }
                                                }
                    
                                                return txt_situacao_orcamento;
                                            }
                                        },
                                        {
                                            type: "buttons",
                                            fixed: true,
                                            fixedPosition: "right",
                                            buttons: [
                                                {
                                                    hint: "Editar Oportunidade",
                                                    icon: "fa fa-pencil-alt",
                                                    onClick: function(e) {
                                                        View.load('oportunidade/detalhes', function(html_id, detalhes) {
                                                            detalhes.show(e.row.data.id, window.FORMULARIO.EDITAR, e.row.data.funil_vendas_id);
                                                        }, View.ABA.MULTIPLAS);
                                                    }
                                                },
                                            ]
                                        }
                                    ]}
                                >    
                                </DataGridPadrao>
                                <VerticalSpacer/>
                            </React.Fragment>
                            : null }

                            { this.state.solicitacao.length > 0 ?
                            <React.Fragment>
                                <DataGridPadrao
                                    dataSource={this.state.solicitacao}
                                    remoteOperations={false}
                                    columns={[
                                        { 
                                            dataField: App.sessao.dados.parametro_processo.numero_solicitacao_por_processo === "S" ? "numero" : "solicitacao_id",
                                            dataType: "number",
                                            caption: "#",
                                            width: 100,
                                        },
                                        { 
                                            dataField: "solicitacao.versao_processo.nome",
                                            caption: "Processo",
                                            minWidth: 100,
                                        },
                                        {
                                            type: "buttons",
                                            fixed: true,
                                            fixedPosition: "right",
                                            buttons: [
                                                {
                                                    hint: "Visualizar Solicitação",
                                                    icon: "fa",
                                                    cssClass: "icon_solicitacao",
                                                    onClick: function(e) {
                                                        View.load('solicitacao/listar', function(html_id, solicitacao) {
                                                            solicitacao.show(e.row.data.solicitacao_id);
                                                        }, View.ABA.SIM_E_RECARREGAR_SE_ESTIVER_ABERTA);
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                >    
                                </DataGridPadrao>
                                <VerticalSpacer/>
                            </React.Fragment>
                            : null }

                            { this.state.solicitacao_atividades.length > 0 ?
                            <React.Fragment>
                                <DataGridPadrao
                                    dataSource={this.state.solicitacao_atividades}
                                    remoteOperations={false}
                                    columns={[
                                        { 
                                            dataField: App.sessao.dados.parametro_processo.numero_solicitacao_por_processo === "S" ? "numero" : "solicitacao_id",
                                            dataType: "number",
                                            caption: "#",
                                            width: 100,
                                        },
                                        { 
                                            dataField: "solicitacao.versao_processo.nome",
                                            caption: "Processo",
                                            minWidth: 100,
                                        },
                                        { 
                                            dataField: "atividade_descricao",
                                            caption: "Atividade" ,
                                            minWidth: 100
                                        },
                                        {
                                            dataField: "usuario_nome", 
                                            caption: "Solicitante",
                                            minWidth: 100
                                        },
                                        {
                                            dataField: "responsavel_nome", 
                                            caption: "Responsável",
                                            minWidth: 100
                                        },
                                        { 
                                            dataField: "data",
                                            caption: "Início Atividade",
                                            minWidth: 100,
                                            dataType: "datetime",
                                            format: "dd/MM/yyyy HH:mm",
                                            calculateCellValue: function(data) {
                    
                                                if (data.data) {
                                                    return data.data.format_date_time();
                                                }
                                            },
                                        },
                                        { 
                                            dataField: "prazo_execucao",
                                            caption: "Prazo",
                                            minWidth: 100,
                                            dataType: "datetime",
                                            format: "dd/MM/yyyy HH:mm",
                                            calculateCellValue: function(data) {
                    
                                                if (data.prazo_execucao) {
                                                    return data.prazo_execucao.format_date_time();
                                                }
                                            }
                                        },
                                        {
                                            dataField: "situacao",
                                            caption: "Situação da Atividade",
                                            minWidth: 120,
                                            lookup: {
                                                dataSource: [
                                                    {
                                                        "id": "1",
                                                        "descricao": "Concluída"
                                                    }, 
                                                    {
                                                        "id": "5",
                                                        "descricao": "Retornada"
                                                    },
                                                    {
                                                        "id": "9",
                                                        "descricao": "Cancelada"
                                                    },
                                                    {
                                                        "id": "4",
                                                        "descricao": "Não entregue"
                                                    },
                                                    {
                                                        "id": "0",
                                                        "descricao": "Andamento"
                                                    },
                                                    {
                                                        "id": "3",
                                                        "descricao": "Atrasada"
                                                    }
                                                ],
                                                displayExpr: "descricao",
                                                valueExpr: "id"
                                            } 
                                        },
                                        {
                                            type: "buttons",
                                            fixed: true,
                                            fixedPosition: "right",
                                            buttons: [
                                                {
                                                    hint: "Visualizar Atividade",
                                                    icon: "fa fa-list-alt",
                                                    visible: function (e) {
                                                        return (e.row.data.id > 0);
                                                    },
                                                    onClick: function(e) {
                                                        View.load('solicitacao/detalhes', function(html_id, solicitacao_detalhes) {
                                                            solicitacao_detalhes.show(e.row.data.id, window.FORMULARIO.EDITAR);
                                                        }, View.ABA.MULTIPLAS);
                                                    }
                                                },
                                                {
                                                    hint: "Visualizar Solicitação",
                                                    icon: "fa",
                                                    cssClass: "icon_solicitacao",
                                                    onClick: function(e) {
                                                        View.load('solicitacao/listar', function(html_id, solicitacao) {
                                                            solicitacao.show(e.row.data.solicitacao_id);
                                                        }, View.ABA.SIM_E_RECARREGAR_SE_ESTIVER_ABERTA);
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                >    
                                </DataGridPadrao>
                                <VerticalSpacer/>
                            </React.Fragment>
                            : null }

                            { this.state.projeto_atividades.length > 0 ?
                            <React.Fragment>
                                <DataGridPadrao
                                    dataSource={this.state.projeto_atividades}
                                    remoteOperations={false}
                                    columns={[
                                        { 
                                            dataField: "projeto_codigo",
                                            dataType: "number",
                                            caption: "Projeto",
                                            width: 100,
                                        },
                                        { 
                                            dataField: "projeto_nome",
                                            caption: "Projeto Nome",
                                            minWidth: 100,
                                        },
                                        { 
                                            dataField: "codigo",
                                            caption: "Cód. Ativ." ,
                                            minWidth: 100
                                        },
                                        { 
                                            dataField: "atividade_simplificada",
                                            caption: "Atividade" ,
                                        },
                                        {
                                            dataField: "assunto", 
                                            caption: "Assunto",
                                            minWidth: 100
                                        },
                                        {
                                            dataField: "responsavel_nome", 
                                            caption: "Responsável",
                                            minWidth: 100
                                        },
                                        { 
                                            dataField: "prazo",
                                            caption: "Prazo",
                                            minWidth: 100,
                                            dataType: "datetime",
                                            format: "dd/MM/yyyy HH:mm",
                                            calculateCellValue: function(data) {
                                                if (data.prazo) {
                                                    return data.prazo.format_date_time();
                                                }
                                            },
                                        },
                                        { 
                                            dataField: "conclusao",
                                            caption: "Conclusão",
                                            minWidth: 100,
                                            dataType: "datetime",
                                            format: "dd/MM/yyyy HH:mm",
                                            calculateCellValue: function(data) {
                    
                                                if (data.conclusao) {
                                                    return data.conclusao.format_date_time();
                                                }
                                            }
                                        },
                                        {
                                            type: "buttons",
                                            fixed: true,
                                            fixedPosition: "right",
                                            buttons: [
                                                {
                                                    hint: "Visualizar Atividade",
                                                    icon: "fa fa-pencil-alt",
                                                    onClick: function(e) {
                                                        View.load('atividade_projeto/detalhes', function(html_id, atividade_projeto) {
                                                            atividade_projeto.show(e.row.data.projeto_id, e.row.data.id, window.FORMULARIO.EDITAR);
                                                        }, View.ABA.MULTIPLAS);
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                >    
                                </DataGridPadrao>
                                <VerticalSpacer/>
                            </React.Fragment>
                            : null }

                            { this.state.pdca_atividades.length > 0 ?
                            <React.Fragment>
                                <DataGridPadrao
                                    dataSource={this.state.pdca_atividades}
                                    remoteOperations={false}
                                    columns={[
                                        { 
                                            dataField: "pdca_id",
                                            dataType: "number",
                                            caption: "#",
                                            width: 100,
                                        },
                                        { 
                                            dataField: "objetivo",
                                            caption: "PDCA",
                                        },
                                        { 
                                            dataField: "que",
                                            caption: "Atividade" ,
                                            minWidth: 100
                                        },
                                        {
                                            type: "buttons",
                                            fixed: true,
                                            fixedPosition: "right",
                                            buttons: [
                                                {
                                                    hint: "Visualizar PDCA",
                                                    icon: "fa fa-pencil-alt",
                                                    onClick: function(e) {
                                                        var pdca = {
                                                            pdca_id: e.row.data.pdca_id,
                                                            data_inicio: e.row.data.data_inicio,
                                                            data_fim: e.row.data.data_fim,
                                                            progresso: e.row.data.progresso,
                                                            situacao: e.row.data.situacao,
                                                            objetivo: e.row.data.objetivo
                                                        }
                                        
                                                        if (e.row.data.usuarios_equipe.length > 0) {
                                                            pdca.usuarios_equipe = e.row.data.usuarios_equipe;
                                                        }           

                                                        View.load('pdca/atividades', function(html_id, pdca_atividades) {
                                                            pdca_atividades.show(pdca);
                                                        }, View.ABA.MULTIPLAS);
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                >    
                                </DataGridPadrao>
                                <VerticalSpacer/>
                            </React.Fragment>
                            : null }

                            { this.state.tarefa_quadro_lista_cartao.length > 0 ?
                            <React.Fragment>
                                <DataGridPadrao
                                    dataSource={this.state.tarefa_quadro_lista_cartao}
                                    remoteOperations={false}
                                    columns={[
                                        { 
                                            dataField: "nome",
                                            caption: "Cartão",
                                        },
                                        { 
                                            dataField: "nome_lista",
                                            caption: "Lista",
                                        },
                                        { 
                                            dataField: "nome_quadro",
                                            caption: "Quadro",
                                        },
                                        { 
                                            dataField: "data_prazo",
                                            caption: "Prazo",
                                            minWidth: 100,
                                            dataType: "datetime",
                                            format: "dd/MM/yyyy HH:mm",
                                            calculateCellValue: function(data) {
                                                if (data.data_prazo) {
                                                    return data.data_prazo.format_date_time();
                                                }
                                            },
                                        },
                                        { 
                                            dataField: "data_conclusao",
                                            caption: "Conclusão",
                                            minWidth: 100,
                                            dataType: "datetime",
                                            format: "dd/MM/yyyy HH:mm",
                                            calculateCellValue: function(data) {
                                                if (data.data_conclusao) {
                                                    return data.data_conclusao.format_date_time();
                                                }
                                            }
                                        },
                                        {
                                            type: "buttons",
                                            fixed: true,
                                            fixedPosition: "right",
                                            buttons: [
                                                {
                                                    hint: "Visualizar PDCA",
                                                    icon: "fa fa-pencil-alt",
                                                    onClick: function(e) {
                                                        View.load('tarefa/detalhes_cartao', function(html_id, detalhes) {
                                                            detalhes.show(e.row.data.id);
                                                        }, View.ABA.MULTIPLAS);
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                >    
                                </DataGridPadrao>
                                <VerticalSpacer/>
                            </React.Fragment>
                            : null }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}