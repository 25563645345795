import React from 'react';
import DataGridPadrao from './DataGridPadrao';
import DropDownBox from 'devextreme-react/drop-down-box';
//import './SelecaoPadrao.scss';

export default class SelecaoPadrao extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selecionado: props.selecionado ? [props.selecionado] : null,
            placeholder: props.placeholder ?? "",
            dataSource: props.dataSource ?? "",
            readOnly: props.readOnly ?? false,
            fieldRender: props.fieldRender ?? null,
            displayExpr: props.displayExpr ?? null
        };

        this.dropDownBoxRef = React.createRef();

        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGrid_onSelectionChanged = this.dataGrid_onSelectionChanged.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
    }

    get dropDownBox() {
        return this.dropDownBoxRef.current ? this.dropDownBoxRef.current.instance : null;
    }

    render() {
        return (
            <DropDownBox
                ref={this.dropDownBoxRef}
                dataSource={this.state.dataSource}
                value={this.state.selecionado}
                deferRendering={false}
                displayExpr={this.state.displayExpr}
                placeholder={this.props.placeholder}
                showClearButton={this.props.showClearButton}
                onValueChanged={this.syncDataGridSelection}
                contentRender={this.dataGridRender}
                onOpened={this.onOpened}
                stylingMode={this.props.stylingMode}
                fieldRender={this.props.fieldRender}
                readOnly={this.state.readOnly}
            >
            </DropDownBox>
        );
    }

    dataGridRender() {
        let searchPanel = {
            visible: !window.detectmob(),
            width:200,
            placeholder: "Pesquisa Geral"
        };
        return (
            <DataGridPadrao
                dataSource={this.state.dataSource}
                columns={this.props.columns}
                hoverStateEnabled={true}
                selectedRowKeys={this.state.selecionado}
                onSelectionChanged={this.dataGrid_onSelectionChanged}
                searchPanel={searchPanel}
            >
            </DataGridPadrao>
        );
    }

    syncDataGridSelection(e) {
        this.setState({
            selecionado: e.value
        });

        if (this.props.aoAlterar) {
            this.props.aoAlterar(this.state.selecionado && this.state.selecionado.length > 0 ? this.state.selecionado[0] : null);
        }
    }

    dataGrid_onSelectionChanged(e) {
        this.setState({
            selecionado: e.selectedRowKeys,
            selecionadoPropriedades: e.selectedRowsData
        });
        
        if (this.dropDownBox) {
            this.dropDownBox.close();
        }
        
        if (this.props.aoAlterar) {
            let selecionado = this.state.selecionado && this.state.selecionado.length > 0 ? this.state.selecionado[0] : null;
            let selecionadoPropriedades = this.state.selecionadoPropriedades && this.state.selecionadoPropriedades.length > 0 ? this.state.selecionadoPropriedades[0] : null;
            this.props.aoAlterar(selecionado, selecionadoPropriedades);
        }
    }
    
    onOpened(arg) {
        arg.component._popup.option("fullScreen", true);
        arg.component._popup.option("showTitle", true);
        arg.component._popup.option("showCloseButton", true);
        window.set_height(arg.component._popup.content().parents(".dx-popup-wrapper"), 0, true);
        arg.component._popup.content().parents(".dx-popup-fullscreen").css("max-height", "none").css("overflow-y", "scroll");
        arg.component._popup.content().parents(".dx-popup-wrapper").css("width", "100%").css("height", "100%");
        setTimeout(function() {
            arg.component._popup.content().find('.dx-toolbar .dx-texteditor-input').focus();
        }, 300);
        arg.component._popup.content().parent().css({ 
            maxHeight: "none",
            overflowY: "scroll"
        });
    }
}

SelecaoPadrao.defaultProps = {
    columns: [],
    dataSource: null,
    placeholder: "Pesquisar",
    aoAlterar: null,
    displayExpr: null,
    stylingMode: "outlined",
    showClearButton: false,
    readOnly: false,
};