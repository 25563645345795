import React from 'react';
import $ from 'jquery';
import RichTextEditor from '../../../components/RichTextEditor';
import View from '../../../api/view';
import md5 from 'md5';

export default class Mensagem extends React.Component {
        
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.elemKey = md5(Math.random()+new Date()+Math.random()+md5(Math.random()));
    }

    propsToObjState() {
        return {
            chamadoId: this.props.chamadoId,
            chamadoMensagemId: typeof this.props.chamadoMensagemId !== 'undefined' ? this.props.chamadoMensagemId : null,
            editavel: typeof this.props.editavel !== 'undefined' ? this.props.editavel : false,
            mensagem: typeof this.props.mensagem !== 'undefined' ? this.props.mensagem : '',
            tipo: typeof this.props.tipo !== 'undefined' ? this.props.tipo : '',
            processando: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.mensagem !== this.props.mensagem) {
            this.setState(this.propsToObjState());
        }
    }

    handleEditorChange(content, editor) {
        this.setState({ mensagem: content });
        if (this.props.aoEditar) this.props.aoEditar(content);
    }

    componentDidMount() {
        let mensagem_item_fusao = $('[mensagem_item_fusao='+this.elemKey+']');

        mensagem_item_fusao.css('cursor', 'pointer');
        mensagem_item_fusao
            .on('mouseover', function() {
                $(this).css('text-decoration', 'underline');
            })
            .on('mouseleave', function(e) {
                e.target.style.removeProperty('text-decoration');
            });

        // Ao clicar sobre o número do chamado fundido, abrir a sua tela de detalhes
        mensagem_item_fusao.off('click');
        mensagem_item_fusao.on('click', function(e){
            var chamado_id = $(this).attr('id');
            View.loadReact(
                window.views.chamado.Detalhes.default,
                {
                    chamadoId: chamado_id
                },
                function(html_id, view) { },
                window.View.ABA.MULTIPLAS
            );
        });
    }
    
    render(){
        if (this.state.editavel) {
            return(
                <div style={{marginBottom: "10px"}}>
                    <RichTextEditor
                        value={this.state.mensagem}
                        onEditorChange={this.handleEditorChange}
                        moduloId={window.MODULOS.CHAMADO}
                    />
                </div>
            );
        }

        // verificar se tem html, se não tiver utilizar nl2br.
        let mensagemHtml = null;
        if (window.is_html(this.state.mensagem)) {
            mensagemHtml = this.state.mensagem;
        }
        else {
            mensagemHtml = window.nl2br(this.state.mensagem);
        }

        const elemKey = this.elemKey;

        // Neste trecho, adiciono um evento de clique em cada um dos chamados que foram fundidos com o chamado atual
        if (+this.state.tipo === window.TIPO_MENSAGEM_CHAMADO.MENSAGEM_TIPO_FUSAO_CHAMADOS) {
            let chamados_fusao = mensagemHtml.substring(
                // Retorno os números dos chamados que foram fundidos
                mensagemHtml.lastIndexOf('chamado(s)')+'chamado(s)'.length+1,
                mensagemHtml.lastIndexOf('foi(ram)')-1
            )
            .trim().split(',') // Crio um array, onde cada elemento é o chamado fundido
            .map(function(item) {
                // Adiciono um span ao elemento, para poder adicionar um evento de clique mais a frente
                return item = '<span mensagem_item_fusao="'+elemKey+'" id="'+item.substring(1)+'">'+item+'</span>';
            })
            .join(', '); // Transformo em string novamente após realizar as devidas manipulações

            // Monto o corpo da mensagem novamente, concatenando com o elemento manipulado acima
            mensagemHtml = 'O(s) chamado(s) '+chamados_fusao+' foi(ram) fundido(s) com este chamado.<br/>';           
        }

        // Neste trecho, adiciono um evento de clique no chamado ao qual este chamado foi fundido
        if (+this.state.tipo === window.TIPO_MENSAGEM_CHAMADO.MENSAGEM_TIPO_ENCERRAMENTO) {
            const mensagemPrefixoFusao = 'Este chamado foi fundido com o chamado ';
            const index = mensagemHtml.indexOf(mensagemPrefixoFusao);
            if (index >= 0) {
                const chamado = mensagemHtml.substring(mensagemPrefixoFusao.length, mensagemHtml.length);
                let chamados_fusao = '<span mensagem_item_fusao="'+elemKey+'" id="'+chamado.substring(1)+'">'+chamado+'</span>';
                console.log(chamado);
                // Monto o corpo da mensagem novamente, concatenando com o elemento manipulado acima
                mensagemHtml = mensagemPrefixoFusao + chamados_fusao;
            }          
        }

        return(
            <div className="mensagemChamado" dangerouslySetInnerHTML={{ __html: mensagemHtml }}>
            </div>
        );
    }
}