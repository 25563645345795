import React from 'react';
import App from '../../api/app';
import WS from '../../api/ws';
import DateBox from 'devextreme-react/date-box';
import { reclassificar } from '../../datasource/Chamado';
import LabelCard from './LabelCard';

export default class CardPrevisaoRetorno extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
        this.obtendoNovaPrevisao = false;
    }

    propsToObjState(prevProps) {
        let selecionado = this.props.detalhes && this.props.detalhes.data_previsao_retorno ? window.date_create(this.props.detalhes.data_previsao_retorno) : null;
        let solicitanteId = this.props.detalhes ? this.props.detalhes.solicitante_id : null;
        let solicitantePodeAlterarPrevisaoRetorno = this.props.detalhes ? this.props.detalhes.solicitante_pode_alterar_previsao_retorno : null;
        let tipoGestaoTempo = this.props.detalhes ? this.props.detalhes.tipo_gestao_tempo : null;
        let horasUteis = this.props.detalhes ? this.props.detalhes.horas_uteis : null;

        if (this.props.novaMensagem
            && this.props
            && this.props.reclassificacoesMemoria) {

            if (this.props.reclassificacoesMemoria.usuario_solicitante_id) {
                solicitanteId = this.props.reclassificacoesMemoria.usuario_solicitante_id;
            }

            if (this.props.reclassificacoesMemoria.chamado_motivo_solicitante_pode_alterar_previsao_retorno) {
                solicitantePodeAlterarPrevisaoRetorno = this.props.reclassificacoesMemoria.chamado_motivo_solicitante_pode_alterar_previsao_retorno;
            }

            if (this.props.reclassificacoesMemoria.chamado_motivo_tipo_gestao_tempo) {
                tipoGestaoTempo = this.props.reclassificacoesMemoria.chamado_motivo_tipo_gestao_tempo;
            }

            if (this.props.reclassificacoesMemoria.chamado_motivo_horas_uteis) {
                horasUteis = this.props.reclassificacoesMemoria.chamado_motivo_horas_uteis;
            }

            if (this.props.reclassificacoesMemoria.data_previsao_retorno) {
                selecionado = window.date_create(this.props.reclassificacoesMemoria.data_previsao_retorno);
            }
        }
        
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: selecionado,
            novaMensagem: this.props.novaMensagem ?? false,
            solicitanteId: solicitanteId,
            solicitantePodeAlterarPrevisaoRetorno: solicitantePodeAlterarPrevisaoRetorno,
            tipoGestaoTempo: tipoGestaoTempo,
            tipo: +tipoGestaoTempo === window.GESTAO_TEMPO.HORAS_UTEIS ? 'datetime' : 'date',
            horasUteis: horasUteis
        };
    }
    
    componentDidMount() {
        this.obterNovoPrazo();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes.data_previsao_retorno !== this.props.detalhes.data_previsao_retorno
            || prevProps.detalhes.tipo_gestao_tempo !== this.props.detalhes.tipo_gestao_tempo
            || prevProps.detalhes.solicitante_id !== this.props.detalhes.solicitante_id
            || prevProps.detalhes.rascunho !== this.props.detalhes.rascunho
            || prevProps.novaMensagem !== this.props.novaMensagem
            || !window.isEquivalent(prevProps.reclassificacoesMemoria, this.props.reclassificacoesMemoria)) {

            const novoState = this.propsToObjState(prevProps);
            
            if (novoState.novaMensagem
                && novoState.detalhes
                && +novoState.tipoGestaoTempo === window.CHAMADO_MOTIVO_TEMPO.HORAS_UTEIS
                && (
                    prevProps.novaMensagem !== this.props.novaMensagem
                    || (prevProps.reclassificacoesMemoria && this.props.reclassificacoesMemoria && prevProps.reclassificacoesMemoria.chamado_motivo_id !== this.props.reclassificacoesMemoria.chamado_motivo_id)
                )
            ) {
                this.obterNovoPrazo(novoState);
            }
            else {
                this.setState(novoState);
            }
        }
    }

    obterNovoPrazo(novoState) {
        let stateLocal = novoState ? novoState : this.state;
        if (!this.obtendoNovaPrevisao && stateLocal.novaMensagem && stateLocal.detalhes) {
            if (+stateLocal.tipoGestaoTempo === window.CHAMADO_MOTIVO_TEMPO.HORAS_UTEIS) {
                this.obtendoNovaPrevisao = true;
                WS.get("chamado/get_previsao_retorno/",
                    {
                        calendario_trabalho_id: (App.sessao.dados.calendario_trabalho_id > 0 ? App.sessao.dados.calendario_trabalho_id : 1),
                        horas_uteis: stateLocal.horasUteis
                    },
                    (response) => {
                        const selecionadoAnterior = stateLocal.selecionado;

                        const selecionado = {
                            previousValue: selecionadoAnterior,
                            value: window.date_create(response.trim()) || stateLocal.selecionado
                        }

                        stateLocal.selecionado = selecionado.value;
                        
                        if (novoState) {
                            this.setState(stateLocal);
                        }
                        
                        if (+selecionadoAnterior !== +stateLocal.selecionado.value) {
                            if (stateLocal.novaMensagem/* && this.state.carregarNovoPrazo*/) {
                                this.aoAlterar(selecionado);
                            }
                        }
                        this.obtendoNovaPrevisao = false;
                    }
                );
            }
        }
    }

    aoAlterar(selecionado) {
        const selecionadoAnterior = this.state.selecionado;

        if (+selecionadoAnterior !== +selecionado.value) {
            this.setState({ selecionado: selecionado.value });
        }

        if (!this.props.detalhes && !this.props.detalhes.id) return false;
        if (!this.state.novaMensagem) {
            reclassificar(
                {
                    "chamado_id": this.props.detalhes.id,
                    "data_previsao_retorno": window.formatDateTime(selecionado.value, true),
                },
                (this.props.aoAlterar ? this.props.aoAlterar : null),
                (response => this.setState({ selecionado: selecionado.previousValue }))
            );
        }
        else {
            if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
        }
    }

    render() {
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <LabelCard label={this.props.label} novaMensagem={this.state.novaMensagem} />
                <DateBox
                    value={this.state.selecionado}
                    type={this.state.tipo}
                    useMaskBehavior={true}
                    stylingMode="filled"
                    onValueChanged={this.aoAlterar}
                    readOnly={
                        App.sessao.dados.tipo !== window.USUARIO_TIPO.ANALISTA
                        || (App.sessao.dados.tipo === window.USUARIO_TIPO.ANALISTA && +App.sessao.dados.usuario_id === +this.state.solicitanteId && this.state.solicitantePodeAlterarPrevisaoRetorno === 'N')
                        || (!this.state.detalhes.rascunho && (!App.verifica_permissao(App.temp.empresa, "reclassificar_chamado") || [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.state.detalhes.situacao)))}
                />
            </div>
        );
    }
}

CardPrevisaoRetorno.defaultProps = {
    label: "Previsão de Retorno",
};