import React from 'react';
import TextBox from 'devextreme-react/text-box';

export default class CardAvaliacao extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: this.props.detalhes ? this.props.detalhes.nota_avaliacao_nome : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes) {
            this.setState(this.propsToObjState());
        }
    }

    render() {
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <label className="small text-muted">{this.props.label}</label>
                <TextBox
                    value={this.state.selecionado}
                    stylingMode="filled"
                    readOnly={true}
                />
            </div>
        );
    }
}

CardAvaliacao.defaultProps = {
    label: "Avaliação",
};