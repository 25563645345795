import React from 'react';
import moment from 'moment';

export default class DataEnvio extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.dataEnvioClick = this.dataEnvioClick.bind(this);
    }

    propsToObjState() {
        return {
            dataEnvio: this.props.dataEnvio,
            dataEnvioFromNow: moment(this.props.dataEnvio).fromNow(),
            extenso: this.props.extenso ?? false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.extenso !== this.props.extenso) {
            this.setState(this.propsToObjState());
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState(this.propsToObjState());
        }, 60000); // 1 minuto
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    dataEnvioClick() {
        if (this.props.aoAlterarTipoExibicao) {
            this.props.aoAlterarTipoExibicao(!this.state.extenso);
        }
    }

    render() {
        return (
            <div className="text-muted small" title={this.state.dataEnvio.format_date_time()}>
                <span onClick={this.dataEnvioClick} style={{cursor: 'pointer'}}>{this.state.extenso ? this.state.dataEnvio.format_date_time() : this.state.dataEnvioFromNow}</span>
            </div>
        );
    }
}