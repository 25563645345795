import WS from '../api/ws';
import React from 'react';
import $ from 'jquery';

export default class FormularioPersonalizado extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();

        this.main = this.props.main ?? {};
        this.formularioRef = React.createRef();
        this.renderFormulario = this.renderFormulario.bind(this);
    }

    propsToObjState() {
        return {
            readOnly: this.props.readOnly ?? false,
            urlGet: this.props.urlGet ?? null,
            urlGetParams: this.props.urlGetParams ?? {},
        };
    }

    componentDidMount() {
        this.renderFormulario();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.readOnly !== this.props.readOnly
            || prevProps.urlGet !== this.props.urlGet
            || !window.isEquivalent(prevProps.urlGetParams, this.props.urlGetParams)) {
            this.setState(this.propsToObjState());

            if (this.formularioRef) {
                this.renderFormulario();
            }
        }
    }

    render() {
        return (
            <div ref={this.formularioRef}></div>
        );
    }

    renderFormulario() {
        let main = this.main;
        
        main.html = $(
            '<div class="aba_personalizado">'+
                '<div class="titulo_formulario">Campos Personalizados</div>' +
                '<div class="form-editor">' +
                        
                    '<div class="form-grupos panel-body" style="padding: 0px;">' +
                    '</div>' +

                    '<div class="form-grupo" template-grupo template-ref="" style="display: none;">' +
                        '<div>' +
                            '<h3 class="text-primary lbl_grupo_nome"></h3>' +
                        '</div>' +
                    '</div>' +

                    '<div class="form-grupo-campo" template-campo template-ref="" style="display: none;">' +
                        '<div class="div_campo form-group">' +
                            '<span class="lbl_obrigatorio text-danger">* </span>' +
                            '<label class="lbl_nome">{Nome}</label>' +
                            '<div class="div_input">' +
                            '</div>' +
                        '</div>' +
                    '</div>' +

                '</div>' +
            '</div>'
        );

        main.html_id = 'html_id';
        main.aba_personalizado = main.html;
        main.titulo_formulario = main.html.find('.titulo_formulario');
        main.form_editor = main.html.find('.form-editor');
        main.form_grupos = main.html.find('.form-grupos');
        main.template_grupo = main.html.find('[template-grupo]');
        main.template_campo = main.html.find('[template-campo]');
        main.lbl_nome = main.html.find('.lbl_nome');

        WS.get(
            this.state.urlGet+ "/",
            this.state.urlGetParams,
            (response) => {
                if (!response) {
                    main.titulo_formulario.html('');
                    $(this.formularioRef.current).html('');
                }
                main.campos_personalizados = response;
                main.titulo_formulario.html(response.nome);

                if(main.campos_personalizados.registros){
                    $.each(main.campos_personalizados.registros, function(index, value) {
                        if(index === "campo_anexo"){
                            if(value){
                                $.each(value, function(index2, value2) {
                                    if(value2){
                                        var arr = value2.split(";");
                                        var id_revisao = parseInt(arr[0]);
                                        var nome = arr[1];
                                        $.each(main.campos_personalizados.grupos, function(index3, value3) {
                                            $.each(value3.elementos, function(index4, value4) {
                                                if(index2 === value4.id_elemento){
                                                    value4.valor = id_revisao;
                                                    value4.documento_revisao = {};
                                                    value4.documento_revisao.nome_arquivo = nome;
                                                    value4.documento_revisao.mime_type = window.extensao_to_mimetype(nome.substring(nome.lastIndexOf(".") + 1, nome.length));
                                                }
                                            });
                                        });
                                    }
                                });
                            }
                        }else if(index === "selecao_documento"){
                            $.each(value, function(index2, value2) {
                                $.each(main.campos_personalizados.grupos, function(index3, value3) {
                                    $.each(value3.elementos, function(index4, value4) {
                                        if(index2 === value4.id_elemento){
                                            value4.valor = value2;
                                        }
                                    });
                                }); 
                            });
                        }else if(index === "campo_tabela"){
                            $.each(value, function(index2, value2) {
                                if(typeof value2 === "string"){
                                    /**
                                     * Este ajuste foi feito para adequar o JSON Salvo com erros na estrutura para JSON válido, pois antes estava salvando JSON's inválidos
                                     * e isto quebrava a visualização, então ele preve o erro e corrige.
                                     * (\"{2})(\w.+?)\"{2} -> Separo em 2 grupos de match
                                     * O primeiro grupo deve pegar tudo que tiver exatamente 2 aspas + Texto e finalize com exatamente 2 aspas
                                     * EX: ""NOME DE USUARIO""
                                     * E substituo por aspas + valor do 2 grupo que contém o texto  + fecha aspas
                                     * Ex: "NOME DE USUARIO"
                                     * **/
                                    // eslint-disable-next-line no-useless-escape
                                    try{  value2 = JSON.parse(value2.replace(/(\"{2})(\w.+?)\"{2}/gm, '"$2"').replace(/(\r\n|\n|\r)/gm," ")); }
                                    // eslint-disable-next-line no-useless-escape
                                    catch(e){ console.warn(value2,value2.replace(/(\"{2})(\w.+?)\"{2}/gm, '"$2"').replace(/(\r\n|\n|\r)/gm," ")) }
                                }
                                
                                if(value2){
                                    $.each(main.campos_personalizados.grupos, function(index3, value3) {
                                        $.each(value3.elementos, function(index4, value4) {
                                            if(index2 === value4.id_elemento){
                                                value4.tamanho_render = value2.tamanho_render;
                                                value4.valor = value2.valor;
                                                if(typeof value4.valor !== "undefined"){
                                                    $.each(value4.valor, function(index5, value5) {
                                                        if(value5.revisao != null){
                                                            var arr = value5.revisao.split(";");
                                                            var id_revisao = parseInt(arr[0]);
                                                            var nome = arr[1];
                                                            value5.valor = id_revisao;
                                                            value5.documento_revisao = {};
                                                            value5.documento_revisao.nome_arquivo = nome;
                                                            value5.documento_revisao.mime_type = window.extensao_to_mimetype(nome.substring(nome.lastIndexOf(".") + 1, nome.length));
                                                        }
                                                    })
                                                }
                                            }
                                        });
                                    });
                                }
                            });
                        }
                    });
                }

                window.require(['./assets/view/formulario/util'], (formulario_util) => {
                    var util = new formulario_util();
                    util.render_previsao({
                        div_formulario: main,
                        template_campo: main.template_campo,
                        template_grupo: main.template_grupo,
                        obj_formulario: main.campos_personalizados,
                        disabled_all: this.state.readOnly,
                        liberar_campos: !this.state.readOnly
                    });

                    if(response.registros){
                        $.each(response.registros, function(idx, obj) {
                            if(idx === "campo_geral"){
                                Object.keys(response.registros.campo_geral).map(function(objectKey, index) {
                                    main.aba_personalizado.find('[id_elemento=' + objectKey + ']').val(response.registros.campo_geral[objectKey]);
                                    main.aba_personalizado.find('[id_elemento=' + objectKey + ']').change();
                                    main.aba_personalizado.find('[id_elemento=' + objectKey + ']').blur();
                                    return objectKey;
                                });
                            }else if(idx === "campo_decimal"){
                                Object.keys(response.registros.campo_decimal).map(function(objectKey, index) {
                                    if(response.registros.campo_decimal[objectKey]){
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').val((response.registros.campo_decimal[objectKey]).replace(".",","));
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').change();
                                        main.aba_personalizado.find('[id_elemento=' + objectKey + ']').blur();
                                    }
                                    return objectKey;
                                });
                            }else if(idx === "lista_opcoes"){
                                Object.keys(response.registros.lista_opcoes).map(function(objectKey, index) {
                                    var opt = main.aba_personalizado.find('[id_elemento=' + objectKey + ']').find('option');
                                    var opt_bd = (response.registros.lista_opcoes[objectKey]);
                                    if (opt_bd != null) opt_bd = opt_bd.split(";");
                                    var indice = 0;
                                    
                                    if(opt && opt_bd){
                                        $.each(opt, function(index, value) {
                                            $.each(opt_bd, function(index2, value2) {
                                                if(value.value === value2){
                                                    value.selected = true;
                                                    $(value).change();
                                                    $(value).blur();
                                                    indice = $(value).index();
                                                    $(value).parent().parent().find("[data-original-index='" + indice + "']").addClass("selected");
                                                }
                                            }); 
                                        }); 
                                    }

                                    return objectKey;
                                });
                            }else if(idx === "radio"){
                                Object.keys(response.registros.radio).map(function(objectKey, index) {
                                    var elemento = main.aba_personalizado.find('[id_elemento=' + objectKey + ']');
                                    elemento.parent().find("input[value='" + response.registros.radio[objectKey] + "']").prop('checked', true);
                                    elemento.parent().find("input[value='" + response.registros.radio[objectKey] + "']").change();
                                    return objectKey;
                                });
                            }else if(idx === "checkbox"){
                                Object.keys(response.registros.checkbox).map(function(objectKey, index) {
                                    var opt_bd = (response.registros.checkbox[objectKey]);
                                    if (opt_bd != null) opt_bd = opt_bd.split(";");
                                    var elemento = main.aba_personalizado.find('[id_elemento=' + objectKey + ']');
                                    if(opt_bd){
                                        $.each(opt_bd, function(index, value) {
                                            elemento.parent().find("input[value='" + value + "']").prop('checked', true);
                                        });
                                    }
                                    return objectKey;
                                });
                            }else if(idx === "'caixa_verificacao'"){
                                $.each(response.registros.caixa_verificacao, function(index, value) {
                                    if(value === "S"){
                                        main.aba_personalizado.find('[id_elemento=' + index + ']').prop('checked', true);
                                    }
                                });
                            }else if(idx === "editor_texto"){
                                setTimeout(function(){
                                    Object.keys(response.registros.editor_texto).map(function(objectKey, index) {
                                        window.tinyMCE.get(main.aba_personalizado.find('[id_elemento=' + objectKey + ']').attr("id")).setContent(response.registros.editor_texto[objectKey]);
                                        return objectKey;
                                    });
                                }, 500);
                            }
                        });
                    }
                    
                    $(this.formularioRef.current).html(
                        main.form_editor[0]
                    );
                });
            }

        );
    }

    getAnexosParams() {
        let params = {};

        if (!this.props.main || !this.props.main.campos_personalizados) return params;

        $(this.props.main.campos_personalizados.grupos).each(function(index, item) {
            $(item.elementos).each(function(index2, item2) {
                if ((item2.tipo === window.ELEMENTO_TIPO.ANEXO) && (item2.anexo)) {
                    params[item2.elem_key] = item2.anexo;
                }
                else if ((item2.tipo === window.ELEMENTO_TIPO.TABELA) && (item2.valor)) {
                    $(item2).each(function(index3, item3) {
                        
                        if(item3.valor){
                            $(item3.valor).each(function(index_anexo, item_anexo) {
                                params[item_anexo.elem_key + '_linha_' + item_anexo.linha] = item_anexo.anexo;
                            });
                        }
                    });
                }
            });
        });

        return params;        
    }

    getPostParams() {
        return {
            ...this.getAnexosParams(),
            campos_personalizados: JSON.stringify(this.main.campos_personalizados)
        }
    }

    validarCamposObrigatorios() {
    
        if (!this.props.main || !this.props.main.form_editor) return true;

        var campos_obrigatorios = '';

        // percorrer campos obrigatorios
        this.props.main.form_editor.find('[required]').not('.selectpicker').each((i, item) => {

            var $item = $(item);

            // Diego Turco - Chamado #3742 @ 19/02/2016
            // Os elementos dos tipos abaixo vai passar a trabalhar com readonly na sua validação,
            // pois essa propriedade permite selecionar o texto do input.
            if ($item.attr('type') === 'text' && typeof $item.attr('maskmoney') == 'undefined') {

                if ((typeof $item.attr('readonly') == 'undefined' && $(item).css('display') !== 'none') || $(item).hasClass('input_consulta_integracao')){

                    // se for um campo de mascara
                    if (typeof $item.attr('maskmoney') !== 'undefined') {
                        // se o valor do campo for vazio, ou o valor sem mascara for zero
                        if ((item.value.trim() === '') || ($item.maskMoney('unmasked')[0] === 0)) {
                            // preparo retorno negativo
                            campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                            // mostrar mensagem de campo obrigatorio
                            this.alertaCampoObrigatorio($item);
                        }
                    }
                    else {
                        if (item.value.trim() === '') {
                            // preparo retorno negativo
                            campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                            // mostrar mensagem de campo obrigatorio
                            this.alertaCampoObrigatorio($item);
                        }
                    }

                }else{
                    $item.tooltip('destroy');
                }

            }else if (typeof $item.attr('disabled') == 'undefined' && $(item).css('display') !== 'none') {

                // se for um campo de mascara
                if (typeof $item.attr('maskmoney') !== 'undefined') {
                    // se o valor do campo for vazio, ou o valor sem mascara for zero
                    if (item.value.trim() === "") {
                        // preparo retorno negativo
                        campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                        // mostrar mensagem de campo obrigatorio
                        this.alertaCampoObrigatorio($item);
                    }
                }else if($item.attr('type') === 'date'
                         && typeof $item.attr('disabled') == 'undefined'
                         && typeof $item.attr('readonly') == 'undefined'){

                    if (item.value.trim() === '') {
                        // preparo retorno negativo
                        campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                        // mostrar mensagem de campo obrigatorio
                        this.alertaCampoObrigatorio($item);
                    }
                }
                else {
                    if (item.value.trim() === '' && $item.attr('type') !== 'date') {
                        // preparo retorno negativo
                        campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                        // mostrar mensagem de campo obrigatorio
                        this.alertaCampoObrigatorio($item);
                    }
                }

            }
            else {
                $item.tooltip('destroy');
            }

        });

        // verifico obrigatoriedade em lista tipo checkbox
        this.props.main.form_editor.find('.vld_lista_check').each((i, item) => {

            var $item = $(item);
            
            // Verifica se o campo não está desabilitado
            if (!$item[0]['children'][0]['disabled'] && $item[0]['children'][0]['style']['display'] !== "none") {

                var checks_selecionados = $item.find('input:checked');
                var elem_tooltip = $item.parent().find('.lbl_nome');
                // se não encontrar nada selecionado
                if (!checks_selecionados || checks_selecionados.length === 0) {
                    // mostrar mensagem de campo obrigatorio
                    campos_obrigatorios += this.formGetElementoById($(item).find("input:first").attr("id_elemento")).nome + "<br />";
                    this.alertaCampoObrigatorio(elem_tooltip);
                }
                else {
                    elem_tooltip.tooltip('destroy');
                }
            }
        });

        // verifico obrigatoriedade em lista tipo radio
        this.props.main.form_editor.find('.vld_lista_radio').each((i, item) => {
            var $item = $(item);

            // Verifica se o campo não está desabilitado
            if (!$item[0]['children'][0]['disabled'] && $item[0]['children'][0]['style']['display'] !== "none") {

                var radios_selecionados = $item.find('input:checked');
                var elem_tooltip = $item.parent().find('.lbl_nome');
                // se não encontrar nada selecionado
                if (!radios_selecionados || radios_selecionados.length === 0) {
                    // mostrar mensagem de campo obrigatorio
                    campos_obrigatorios += this.formGetElementoById($(item).find("input:first").attr("id_elemento")).nome + "<br />";
                    this.alertaCampoObrigatorio(elem_tooltip);
                }
                else{
                    elem_tooltip.tooltip('destroy');
                }
            }
        });

        // verifico obrigatoriedade em lista tipo option com seleção multipla
        this.props.main.form_editor.find('select.selectpicker[required]').each((i, item) => {

            var $item = $(item);

            // Verifica se o campo não está desabilitado
            if (!$item[0]['disabled'] && $item[0]['style']['display'] !== "none") {

                var options_selecionados = $item.val();
                var elem_tooltip = $item.closest('.div_campo').find('.lbl_nome');
                // se não encontrar nada selecionado
                if (!options_selecionados || options_selecionados.length === 0) {
                    // mostrar mensagem de campo obrigatorio
                    campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                    this.alertaCampoObrigatorio(elem_tooltip);
                }
                else {
                    elem_tooltip.tooltip('destroy');
                }
            }
        });

        if(campos_obrigatorios !== ''){
            window.alert_modal('<i class="fa fa-exclamation-triangle pull-left" aria-hidden="true"></i> Campos Obrigatórios !',' Por gentileza, preencha os campos abaixo: <br /><br /> ' + campos_obrigatorios);
            return false;
        }

        return true;
    };

    alertaCampoObrigatorio($item, placement) {
        $item.tooltip({title: 'Campo obrigatorio', placement: typeof placement != 'undefined' ? placement : 'auto'});
        $item.tooltip('show');
    };

    validarDataForm() {
        if (!this.props.main || !this.props.main.form_editor) return true;

        var now = new Date();
        var day = ("0" + now.getDate()).slice(-2);
        var month = ("0" + (now.getMonth() + 1)).slice(-2);
        var hoje = now.getFullYear()+"-"+(month)+"-"+(day);
        var campos_obrigatorios = '';
        
        // percorrer campos de data
        this.props.main.form_editor.find('[type="date"]').each(function(i, item) {
            var $item = $(item);

            // se o campo não estiver desabilitado
            if (typeof ($item.attr('disabled') || $item.attr('readonly')) == 'undefined' && $(item).css('display') !== 'none') {

                if(item.value !== '' && item.value != null){

                    if($item.attr('data_retroativa') === 'S'){

                        if(window.get_datepicker($item) < hoje){

                            $item.tooltip({title: 'Data Inválida', placement: 'auto'});
                            $item.tooltip('show');

                            campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                        }else{
                            $item.tooltip('destroy');
                        }

                    }

                    if($item.attr('data_retroativa_igual') === 'S'){

                        if(window.get_datepicker($item) <= hoje){

                            $item.tooltip({title: 'Data Inválida', placement: 'auto'});
                            $item.tooltip('show');

                            campos_obrigatorios += this.formGetElementoById($(item).attr("id_elemento")).nome + "<br />";
                        }else{
                            $item.tooltip('destroy');
                        }
                    }
                }
            }

        });

        if(campos_obrigatorios !== ''){
            window.alert_modal('<i class="fa fa-exclamation-triangle pull-left" aria-hidden="true"></i> Data Inválida !',' Data informada fora do período válido, informe corretamente para os campos abaixo: <br /><br /> ' + campos_obrigatorios);
            return false;
        }

        return true;
    }

    validar() {
        if (this.main.campos_personalizados) {
            if (!this.validarCamposObrigatorios() || !this.validarDataForm()) {
                return false;   
            }
        }
        return true;
    }

    formGetElementoById(elemento_id) {
        var elemento = { };

        if (this.props.main && this.props.main.campos_personalizados) {
            $(this.props.main.campos_personalizados.grupos).each(function(obj_grupo_index, obj_grupo) {
                if (Array.isArray(obj_grupo.elementos)) {
                    $(obj_grupo.elementos).each(function(obj_elemento_index, obj_elemento) {
                        if (obj_elemento.id_elemento === elemento_id) {
                            elemento = obj_elemento;
                        }
                    });
                }
            });
        }
        elemento = window.spread({nome: ''}, elemento);
        return elemento;
    }
}

FormularioPersonalizado.defaultProps = {};