import SelecaoPadrao from './SelecaoPadrao';
import { chamadoPrioridadeColunas, chamadoPrioridadeList } from '../datasource/ChamadoPrioridade';

export default class SelecaoChamadoPrioridade extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
    }

    propsToObjState() {
        return {
            dataSource: chamadoPrioridadeList(),
            readOnly: this.props.readOnly ?? null,
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chamadoId !== this.props.chamadoId
            || prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }
}

const displayExpr = (item) => {
    return item && item.nome;
}

SelecaoChamadoPrioridade.defaultProps = {
    aoAlterar: null,
    displayExpr: displayExpr,
    columns: chamadoPrioridadeColunas
};