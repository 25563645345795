import React from 'react';
import App from '../../api/app';
import SelecaoEntidade from '../../components/SelecaoEntidade';
import { reclassificar } from '../../datasource/Chamado';
import LabelCard from './LabelCard';

export default class CardEntidade extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            selecionado: this.props.detalhes ? { tipo: this.props.detalhes.tipo, id: this.props.detalhes.entidade_id ? this.props.detalhes.entidade_id.toString() : null  } : null,
            novaMensagem: this.props.novaMensagem ?? false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes.tipo !== this.props.detalhes.tipo
            || prevProps.detalhes.entidade_id !== this.props.detalhes.entidade_id
            || prevProps.detalhes.rascunho !== this.props.detalhes.rascunho
            || prevProps.novaMensagem !== this.props.novaMensagem) {
            this.setState(this.propsToObjState());
        }
    }

    aoAlterar(selecionado) {
        let selecionadoAnterior = this.state.selecionado;
        if (selecionadoAnterior && (selecionadoAnterior.tipo !== selecionado.tipo || +selecionadoAnterior.entidade_id !== +selecionado.id)) {
            this.setState({ selecionado: selecionado });
        }
        
        if (!this.state.novaMensagem
            && selecionado
            && +this.state.detalhes.id
            && (this.state.detalhes.tipo !== selecionado.tipo || +this.state.detalhes.entidade_id !== +selecionado.id)) {

            reclassificar(
                {
                    chamado_id: +this.state.detalhes.id,
                    entidade_tipo: selecionado.tipo,
                    entidade_id: selecionado.id
                },
                (this.props.aoAlterar ? this.props.aoAlterar : null),
                (response => this.setState({ selecionado: selecionadoAnterior }))
            );
        }
        else {
            if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
        }
    }

    render() {
        let readOnly = !this.state.detalhes.rascunho
                        && (!App.verifica_permissao(App.temp.empresa, "reclassificar_chamado")
                            || [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.state.detalhes.situacao)
                            );
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <LabelCard label={this.props.label} novaMensagem={this.state.novaMensagem} />
                <SelecaoEntidade
                    selecionado={this.state.selecionado}
                    aoAlterar={this.aoAlterar}
                    stylingMode="filled"
                    readOnly={readOnly}
                />
            </div>
        );
    }
}

CardEntidade.defaultProps = {
    label: "Entidade",
    selecionadoTipo: null,
    selecionadoId: null
};