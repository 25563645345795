import React from 'react';
import App from '../../api/app';
import TextBox from 'devextreme-react/text-box';
import CopiarLink from '../../components/CopiarLink';
import { HorizontalSpacer, VerticalSpacer } from '../../components/Spacer';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { ButtonGroup, Item as ButtonGroupItem } from 'devextreme-react/button-group';
import { Button } from 'devextreme-react/button';
import { reclassificar } from '../../datasource/Chamado';
import LabelSituacao from './LabelSituacao';
import InformativoSla from './InformativoSLA';
import InformativoPrazo from './InformativoPrazo';
import FotoThumb from '../../components/FotoThumb';
import LabelMotivo from './LabelMotivo';

export default class CardTopo extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.assuntoChange = this.assuntoChange.bind(this);
        this.btnMensagensClick = this.btnMensagensClick.bind(this);
        this.btnFormularioAvaliacaoClick = this.btnFormularioAvaliacaoClick.bind(this);
        this.btnFormularioAberturaClick = this.btnFormularioAberturaClick.bind(this);
        
        this.btnReferenciasClick = this.btnReferenciasClick.bind(this);
        this.btnRecarregarClick = this.btnRecarregarClick.bind(this);
        this.btnToogleExibirMaisDetalhesClick = this.btnToogleExibirMaisDetalhesClick.bind(this);
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            referenciasCount: this.props.detalhes.oportunidade.length
                                + this.props.detalhes.solicitacao_atividades.length
                                + this.props.detalhes.projeto_atividades.length
                                + this.props.detalhes.pdca_atividades.length
                                + this.props.detalhes.tarefa_quadro_lista_cartao.length,
            mensagensCount: this.props.mensagensCount ?? 0,
            assunto: this.props.detalhes ? this.props.detalhes.assunto : null,
            assuntoOriginal: this.props.detalhes ? this.props.detalhes.assunto : null,
            tipoGestaoTempo: this.props.detalhes ? this.props.detalhes.tipo_gestao_tempo: null,
            horasEstimadas: this.props.detalhes ? this.props.detalhes.horas_previsao_retorno: null,
            dataPrazo: this.props.detalhes ? this.props.detalhes.data_previsao_retorno: null,
            calendarioTrabalhoId: this.props.detalhes ? this.props.detalhes.chamado_motivo_calendario_trabalho_id: null,
            botaoPressionado: this.props.botaoPressionado ?? null,
            exibirMaisDetalhes: this.props.exibirMaisDetalhes ?? null,
            bloquearExibirMaisDetalhes: this.props.bloquearExibirMaisDetalhes ?? null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes !== this.props.detalhes
            || prevProps.exibirMaisDetalhes !== this.props.exibirMaisDetalhes
            || prevProps.bloquearExibirMaisDetalhes !== this.props.bloquearExibirMaisDetalhes)
        {
            this.setState(this.propsToObjState());
        }
    }

    btnMensagensClick() {
        this.setState({
            botaoPressionado: 'mensagens'
        });
        if (this.props.btnMensagensClick) {
            this.props.btnMensagensClick();
        }
    };

    btnFormularioAvaliacaoClick() {
        this.setState({
            botaoPressionado: 'avaliacao'
        });
        if (this.props.btnFormularioAvaliacaoClick) {
            this.props.btnFormularioAvaliacaoClick();
        }
    }

    btnFormularioAberturaClick() {
        this.setState({
            botaoPressionado: 'formularioAbertura'
        });
        if (this.props.btnFormularioAberturaClick) {
            this.props.btnFormularioAberturaClick();
        }
    }

    btnReferenciasClick() {
        this.setState({
            botaoPressionado: 'referencias'
        });
        if (this.props.btnReferenciasClick) {
            this.props.btnReferenciasClick();
        }
    };

    btnRecarregarClick() {
        if (this.props.btnRecarregarClick) {
            this.props.btnRecarregarClick();
        }
    };

    btnToogleExibirMaisDetalhesClick() {
        if (this.props.btnToogleExibirMaisDetalhesClick) {
            this.setState({exibirMaisDetalhes: this.props.btnToogleExibirMaisDetalhesClick()});
        }
    }

    assuntoChange(e) {
        if (e.value !== this.state.assunto) {
            const assuntoAnterior = this.state.assunto;
            this.setState({ assunto: e.value });
            reclassificar(
                {
                    "chamado_id": this.state.detalhes.id,
                    "assunto": e.value,
                },
                (this.props.btnRecarregarClick ? this.props.btnRecarregarClick : null),
                () => {
                    this.setState({ assunto: assuntoAnterior });
                }
            );
        }
    }

    render() {
        let readOnlyAssunto = !this.state.detalhes.rascunho
                                && (!App.verifica_permissao(App.temp.empresa, "reclassificar_chamado")
                                    || [window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.state.detalhes.situacao)
                                    );

        return (
        <div className="panel panel-default">
            <div className="list-group">
                <div className="list-group-item" style={{padding: '10px'}}>
                    { this.state.detalhes ?
                    <React.Fragment>
                        <Toolbar>
                            <ToolbarItem location="before">
                                <b>#{this.state.detalhes.id} <CopiarLink style={{ padding: '0px' }} url={"#/chamado/"+this.state.detalhes.id}><span className="far fa-copy"></span></CopiarLink></b>
                                <HorizontalSpacer/>
                            </ToolbarItem>
                            <ToolbarItem location="before" visible={+this.state.detalhes.entidade_id > 0}>
                                <div style={{display: 'inline-flex', alignItems: 'center' }} >
                                    <FotoThumb
                                        tamanho="28"
                                        documentoId={this.state.detalhes.entidade_logo_id}
                                        imgAlternativa="assets/img/imagem_empresa_padrao.svg"
                                        titulo={this.state.detalhes.entidade_nome}
                                        />
                                    <div className="visible-lg">
                                        <div style={{display: 'inline-flex', alignItems: 'center' }}>
                                            <HorizontalSpacer/>
                                            <div>{this.state.detalhes.entidade_nome}</div>
                                            <HorizontalSpacer/>
                                        </div>
                                    </div>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="before" visible={+this.state.detalhes.solicitante_id > 0}>
                                <div style={{display: 'inline-flex', alignItems: 'center' }} title="Solicitante">
                                    <FotoThumb tamanho="28" documentoId={this.state.detalhes.usuario_solicitante_foto_id} imgAlternativa="assets/img/imagem_usuario_padrao.svg" titulo="Solicitante" />
                                    <div className="visible-lg">
                                        <div style={{display: 'inline-flex', alignItems: 'center' }}>
                                            <HorizontalSpacer/>
                                            <div>{this.state.detalhes.solicitante_nome}</div>
                                            <HorizontalSpacer/>
                                        </div>
                                    </div>                                    
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="before" visible={+this.state.detalhes.usuario_responsavel_id > 0}>
                                <div style={{display: 'inline-flex', alignItems: 'center' }} title="Responsável">
                                    <FotoThumb tamanho="28" documentoId={this.state.detalhes.foto_usuario_responsavel} imgAlternativa="assets/img/imagem_usuario_padrao.svg" titulo="Responsável"/>
                                    <div className="visible-lg">
                                        <div style={{display: 'inline-flex', alignItems: 'center' }}>
                                            <HorizontalSpacer/>
                                            <div>{this.state.detalhes.usuario_responsavel_nome}</div>
                                            <HorizontalSpacer/>
                                        </div>
                                    </div>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="before">
                                <LabelSituacao detalhes={this.state.detalhes}/>
                            </ToolbarItem>
                            <ToolbarItem location="before">
                                <LabelMotivo detalhes={this.state.detalhes}/>
                            </ToolbarItem>
                            <ToolbarItem location="after" visible={!this.state.detalhes.rascunho}>
                                <div className="small">
                                    <InformativoSla viewHtmlId={this.props.viewHtmlId ?? null} detalhes={this.state.detalhes} autoUpdate="true"/>
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after" visible={!this.state.detalhes.rascunho && +this.state.detalhes.situacao !== window.CHAMADO_SITUACAO.ENCERRADO && +this.state.detalhes.situacao !== window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE}>
                                <div className="small visible-lg">
                                    <InformativoPrazo
                                        TipoGestaoTempo={this.state.tipoGestaoTempo}
                                        HorasEstimadas={this.state.horasEstimadas}
                                        DataPrazo={this.state.dataPrazo}
                                        CalendarioTrabalhoId={this.state.calendarioTrabalhoId}
                                        ExibirTituloDataPrazo={true}
                                        TituloDataPrazo='Previsão de Retorno'
                                    />
                                </div>
                            </ToolbarItem>
                            <ToolbarItem location="after" visible={!this.state.detalhes.rascunho}>
                                <ButtonGroup
                                    selectionMode="single"
                                    stylingMode="text"
                                    selectedItemKeys={[this.state.botaoPressionado]}
                                    keyExpr="acao"
                                >
                                    <ButtonGroupItem
                                        acao="formularioAbertura"
                                        icon="fas fa-clipboard-list"
                                        hint="Formulário"
                                        onClick={this.btnFormularioAberturaClick}
                                        visible={+this.state.detalhes.formulario_abertura_id > 0}
                                    />
                                    <ButtonGroupItem
                                        acao="mensagens"
                                        icon="fas fa-comments"
                                        hint="Mensagens"
                                        onClick={this.btnMensagensClick}
                                        text={this.state.mensagensCount > 0 ? this.state.mensagensCount : null}
                                    />
                                    <ButtonGroupItem
                                        acao="referencias"
                                        icon="fas fa-sitemap"
                                        hint="Referências"
                                        onClick={this.btnReferenciasClick}
                                        text={this.state.referenciasCount > 0 ? this.state.referenciasCount : null}
                                    />
                                    <ButtonGroupItem
                                        acao="avaliacao"
                                        icon="fas fa-star-half-alt"
                                        hint="Avaliação"
                                        onClick={this.btnFormularioAvaliacaoClick}
                                        visible={+this.state.detalhes.formulario_avaliacao_id > 0 && +this.state.detalhes.situacao === window.CHAMADO_SITUACAO.ENCERRADO}
                                    />
                                </ButtonGroup>
                            </ToolbarItem>
                            <ToolbarItem location="after" visible={!this.state.detalhes.rascunho}>
                                <ButtonGroup
                                    selectionMode="single"
                                    stylingMode="text"
                                    keyExpr="acao"
                                    selectedItemKeys={[this.state.exibirMaisDetalhes ? 'exibirMaisDetalhes' : null]}
                                    disabled={this.state.bloquearExibirMaisDetalhes}
                                >
                                    <ButtonGroupItem
                                        acao="exibirMaisDetalhes"
                                        onClick={this.btnToogleExibirMaisDetalhesClick}
                                        hint="mais informações..."
                                        icon="fa fa-users-cog"
                                        stylingMode="text"
                                    />
                                </ButtonGroup>
                            </ToolbarItem>
                            <ToolbarItem location="after" visible={!this.state.detalhes.rascunho}>
                                <Button
                                    onClick={this.btnRecarregarClick}
                                    hint="Recarregar"
                                    icon="fas fa-sync-alt"
                                    stylingMode="text"
                                />
                            </ToolbarItem>
                        </Toolbar>
                    </React.Fragment>
                    : null }
                    
                    <VerticalSpacer/>
                    <TextBox
                        stylingMode="filled"
                        value={this.state.assunto}
                        width="100%"
                        placeholder="Insira aqui o assunto. Seja breve nas palavras."
                        onValueChanged={this.assuntoChange}
                        readOnly={readOnlyAssunto}
                    >
                    </TextBox>
                </div>
            </div>
        </div>
        );
    }
}