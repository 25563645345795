import React from 'react';
import App from '../../api/app';
import DataGridPadrao from '../../components/DataGridPadrao';
import { Column, Button as DataGridButton } from 'devextreme-react/data-grid';
import { reclassificar } from '../../datasource/Chamado';
//import { listarEmails } from '../../datasource/Usuario';
import { Template } from 'devextreme-react/core/template';
import LabelCard from './LabelCard';
export default class CardEmailsAdicionais extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToObjState();
        this.aoAlterar = this.aoAlterar.bind(this);
        this.btnAddClick = this.btnAddClick.bind(this);
        this.btnRemoverClick = this.btnRemoverClick.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.toolbarLabelRender = this.toolbarLabelRender.bind(this);

        this.dataGridRef = React.createRef();
    }

    propsToObjState() {
        return {
            detalhes: this.props.detalhes ?? null,
            emails_adicionais: this.props.detalhes ? this.props.detalhes.emails_adicionais : null,
            editavel: this.props.detalhes.rascunho || (App.verifica_permissao(App.temp.empresa, "reclassificar_chamado") && ![window.CHAMADO_SITUACAO.ENCERRADO,window.CHAMADO_SITUACAO.ENCERRADO_INATIVIDADE].includes(+this.props.detalhes.situacao)),
            novaMensagem: this.props.novaMensagem ?? false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.detalhes.emails_adicionais !== this.props.detalhes.emails_adicionais
            || prevProps.detalhes.rascunho !== this.props.detalhes.rascunho
            || prevProps.novaMensagem !== this.props.novaMensagem) {
            this.setState(this.propsToObjState());
        }
    }

    get dataGrid() {
        return this.dataGridRef.current.instance;
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
        {
            location: 'before',
            template: 'toolbarLabel'
        },
        {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'fa fa-plus',
                stylingMode: 'text',
                onClick: this.btnAddClick
            },
            visible: this.state.editavel
        });
    }

    aoAlterar() {
        let selecionado = this.state.emails_adicionais.map(email => email.email).join(';');
        if (!this.state.novaMensagem) {
            reclassificar(
                {
                    "chamado_id": this.state.detalhes.id,
                    "emails_adicionais": selecionado,
                },
                (this.props.aoAlterar ? this.props.aoAlterar : null)
            );
        }
        else {
            if (this.props.aoAlterar) this.props.aoAlterar(selecionado);
        }
    }

    btnAddClick(e) {
        this.dataGrid.addRow();
    }

    btnRemoverClick(e) {
        let emails = this.state.emails_adicionais;

        const emailsOriginais = emails.map(email => email.email).sort().join(';');

        let novosEmails = [];
        emails.forEach((email) => {
            if (e.row.data.email !== email.email) {
                novosEmails.push(email);
            }
        });

        this.setState({
            emails_adicionais: novosEmails
        });

        if (emailsOriginais !== novosEmails.map(email => email.email).sort().join(';')) {
            this.aoAlterar();
        }
    }

    onEditorPreparing(e) {
        if (e.dataField === "email" && e.parentType === "dataRow") {
            /*
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorName = "dxAutocomplete";
            debugger;
            e.editorOptions.dataSource = listarEmails({
                    usuario: e.editorOptions.value,
                    //cliente_id: cliente_selecionado,
                    //prospect_id: prospect_selecionado,
                    //fornecedor_id: fornecedor_selecionado,
                    //filtra_usuario_colaborador: (filtra_por_colaborador ? App.sessao.dados.tipo : null),
                    //tipo_usuario_chamado_escolhido: tipo_usuario_combobox
            });
            e.editorOptions.valueExpr="email";
            
            e.editorOptions.onValueChanged = (args) => {
                defaultValueChangeHandler(args);
                //this.aoAlterar();
            }
            */
        }
    }

    toolbarLabelRender() {
        return (
            <LabelCard label={this.props.label} novaMensagem={this.state.novaMensagem} />
        );
    }

    render() {
        return (
            <div className="list-group-item" style={{ ...this.props.style, border: '0px' }}>
                <DataGridPadrao
                    ref={this.dataGridRef}
                    dataSource={this.state.emails_adicionais}
                    filterPanel={{visible: false}}
                    filterRow={{visible: false}}
                    headerFilter={{visible: false}}
                    groupPanel={{visible: false}}
                    export={{enabled: false}}
                    allowColumnReordering={false}
                    columnChooser={{enabled: false}}
                    editing={{
                        mode: "cell",
                        allowUpdating: this.state.editavel
                    }}
                    keyExpr="id"
                    showBorders={false}
                    showColumnHeaders={false}
                    showColumnLines={false}
                    columnFixing={{enable: false}}
                    rowAlternationEnabled={false}
                    onEditorPreparing={this.onEditorPreparing}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onRowInserted={() => this.aoAlterar()}
                    onRowUpdated={() => this.aoAlterar()}
                    //onContentReady={}
                    noDataText=""
                    >
                    <Column dataField="email" allowEditing={true}/>
                    <Column type="buttons" width={50} alignment="right">
                        <DataGridButton
                            hint="Remover e-mail"
                            icon="fa fa-times"
                            onClick={this.btnRemoverClick}
                            visible={this.state.editavel}
                        />
                    </Column>
                    <Template name="toolbarLabel" render={this.toolbarLabelRender} />
                </DataGridPadrao>
            </div>
        );
    }
    
}

CardEmailsAdicionais.defaultProps = {
    label: "E-mails adicionais",
};