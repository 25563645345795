import App from '../../api/app';
import WS from '../../api/ws';
import View from '../../api/view';
import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import { temas } from './data.js';

class SelecionarTema extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            temaSelecionadoId: '',
            temaSelecionadoNome: '',
            temaSelecionadoCorPrimaria: '',
            loaderBloqueio: false,
            menuLateral: false,
            buscaAutomatica: false
        };

        this.tema_inicial = 'preto';

        this.menuLateralChanged = this.menuLateralChanged.bind(this);
        this.loaderBloqueioChanged = this.loaderBloqueioChanged.bind(this);
        this.buscaAutomaticaChanged = this.buscaAutomaticaChanged.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.obterParametros = this.obterParametros.bind(this);
        this.salvar = this.salvar.bind(this);
        
        this.obterParametros();
    }

    render() {

        const salvarButtonOptions = {
            icon: 'fas fa-save',
            text: 'Salvar',
            onClick: () => {
                this.salvar();
            }
        };

        const cancelarButtonOptions = {
            icon: 'fas fa-times',
            text: 'Cancelar',
            onClick: () => {
                App.aplicar_tema(this.tema_inicial);
                View.unload(this.props.html_id);
            }
        };

        let cellCorRender = (cell) => {
            return this.renderCorPrimaria(cell.data.corPrimaria);
        };

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <DataGrid
                                dataSource={temas}
                                selection={{ mode: 'single' }}
                                selectedRowKeys={[this.state.temaSelecionadoId]}
                                showBorders={true}
                                hoverStateEnabled={true}
                                keyExpr="id"
                                onSelectionChanged={this.onSelectionChanged}
                                rowAlternationEnabled={true}
                            >
                                <Column dataField="nome" caption="Nome" />
                                <Column dataField="corPrimaria" caption="Cor" cellRender={cellCorRender} />
                            </DataGrid>
                        </div>
                    </div>
                    <div className="col-md-6">                
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Configurações</h3>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        Tema: {this.state.temaSelecionadoNome}
                                    </div>
                                    <div className="col-md-12" style={{display: 'inline-flex'}}>
                                        Cor primária:&nbsp; {this.renderCorPrimaria(this.state.temaSelecionadoCorPrimaria)}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <CheckBox
                                            value={this.state.menuLateral}
                                            onValueChanged={this.menuLateralChanged}
                                            text="Menu Lateral"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <CheckBox
                                            value={this.state.loaderBloqueio}
                                            onValueChanged={this.loaderBloqueioChanged}
                                            text="Loader com bloqueio de tela"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <CheckBox
                                            value={this.state.buscaAutomatica}
                                            onValueChanged={this.buscaAutomaticaChanged}
                                            text="Busca automática no combobox"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer">
                                <Toolbar style={{backgroundColor: 'transparent'}}>
                                    <Item location="before"
                                        widget="dxButton"
                                        options={salvarButtonOptions}
                                         />
                                    <Item location="before"
                                        widget="dxButton"
                                        options={cancelarButtonOptions} />
                                </Toolbar>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderCorPrimaria(corPrimaria) {
        return <div style={{width: '100px', height: '100%', backgroundColor: corPrimaria}} >&nbsp;</div>;
    }

    obterParametros() {
        WS.get("tema/get_parametros/",
            null,
            (response) => {
                this.tema_inicial = response.tema;
                this.setState({
                    temaSelecionadoId: response.tema,
                    temaSelecionadoNome: temas.find(tema => tema.id === response.tema).nome,
                    temaSelecionadoCorPrimaria: temas.find(tema => tema.id === response.tema).corPrimaria,
                    loaderBloqueio: response.loader_bloqueio === 'S',
                    menuLateral: response.menu_lateral === 'S',
                    buscaAutomatica: response.busca_automatica === 'S'
                });
            }
        );
    }

    salvar() {
        WS.post(
            "tema/salvar",
            {
                tema: this.state.temaSelecionadoId,
                loader_bloqueio: this.state.loaderBloqueio,
                menu_lateral: this.state.menuLateral,
                busca_automatica: this.state.buscaAutomatica
            },
            (response) => {
                notify('Tema alterado com sucesso.');
                View.unload(this.props.html_id);
            }
        );
    }

    menuLateralChanged(e) {
        this.setState({
            menuLateral: e.value
        });
    }

    loaderBloqueioChanged(e) {
        this.setState({
            loaderBloqueio: e.value
        });
    }

    buscaAutomaticaChanged(e) {
        this.setState({
            buscaAutomatica: e.value
        });
    }

    onSelectionChanged({ selectedRowsData }) {
        if (!selectedRowsData || selectedRowsData.length === 0) return false;
        const data = selectedRowsData[0];
        if (!data) return false;

        App.aplicar_tema(data.id);
        this.setState({
            temaSelecionadoId: data.id,
            temaSelecionadoNome: data.nome,
            temaSelecionadoCorPrimaria: data.corPrimaria
        });
    }

}

SelecionarTema.defaultProps = {
    abaTitulo: "Temas"
};

export default SelecionarTema;