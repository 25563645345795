import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaChamadoGrupoMotivoNome = {
    dataField: "nome",
    caption: "Grupo de Motivo"
};

export const chamadoGrupoMotivoColunas = [
    colunaChamadoGrupoMotivoNome,
];

export let chamadoGrupoMotivoList = (chamadoId) => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'grupo_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "grupo_motivo_chamado/listar_grupo_motivo_usuario/", 
                data: {
                    dx_grid: true,
                    chamado_id: chamadoId,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "grupo_motivo_chamado/listar_grupo_motivo_usuario/", 
                data: {
                    dx_grid: true,
                    grupo_motivo_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});