import WS from '../api/ws';
import DataSource from 'devextreme/data/data_source';

export const colunaChamadoClassificacaoNome = {
    dataField: "nome",
    caption: "Classificação"
};

export const chamadoClassificacaoColunas = [
    colunaChamadoClassificacaoNome,
];

export let chamadoClassificacaoList = () => new DataSource({
    paging: {
        pageSize: 10
    },
    pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
        showInfo: true
    },
    key: 'classificacao_chamado_id',
    load: (loadOptions) => {
        let params = window.loadOptionsToActionParams(loadOptions);
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado_classificacao/listar_para_selecao/", 
                data: {
                    dx_grid: true,
                    ...params
                },
                func_response: function(res) {
                    let paramsGrid = window.createLoadExtra(res);
                    resolve({...paramsGrid, data: res.data })
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    },
    byKey: (key) => {
        if (!key) return false;
        let promise = new Promise(function(resolve, reject) {
            WS.get({
                route: "chamado_classificacao/listar_para_selecao/", 
                data: {
                    dx_grid: true,
                    classificacao_chamado_id: key
                },
                func_response: function(res) {
                    resolve(res.data)
                },
                func_fail: function(res) {
                    if (res.validation) {
                        reject(new Error(res.validation.messages.map(function(i){ return i.message }).join('<br/>')));
                    }
                }
            });
        });
        return promise;
    }
});