import React from 'react';

export function HorasTrabalhadas(props) {
    if (props.HorasTrabalhadas && props.HorasTrabalhadas !== '000:00' && props.HorasTrabalhadas !== '00:00:00')
    return (
        <div className="text-muted small" title="Horas trabalhadas">
            <span className="far fa-clock"></span> {window.format_time(props.HorasTrabalhadas)}
        </div>
    );
    return null;
}