import React from 'react';
import App from '../../../api/app';
import View from '../../../api/view';
import { HorizontalSpacer } from '../../../components/Spacer';
import { Button } from 'devextreme-react/button';

export function Documentos(props) {

    if (!props.documentos || props.documentos.length === 0) return null;

    const visualizarClick = (documento) => {
        if (documento.mime_type === 'application/pdf') {
            View.load('central_documentos/visualizar_pdf', function(html_id, visualizar_pdf) {
                visualizar_pdf.show(documento.documento_revisao_id);
            });
        }
        else if (documento.mime_type === 'image/png' || documento.mime_type === 'image/bmp' || documento.mime_type === 'image/jpeg' || documento.mime_type === 'image/jpg' || documento.mime_type === 'image/gif') {
            View.load('central_documentos/visualizar_documento', function(html_id, visualizar_documento) {
                visualizar_documento.show(documento.documento_revisao_id);
            });
        }
        else if (window.MIME_TYPE_OFFICE.indexOf(documento.mime_type) >= 0) {
            App.obter_token_publico((token_publico) => {
                let url_bp = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
                let indexOfHTML = url_bp.indexOf('index.html');
                if (indexOfHTML > 0) {
                    url_bp = url_bp.substring(0, indexOfHTML);
                }
                url_bp += '/webservice/index.php/documento/download/?documento_revisao_id=' + documento.documento_revisao_id + 
                                                                    '&token_publico=' + token_publico;

                var url_google = 'https://docs.google.com/viewer?embedded=true&url=' + encodeURIComponent(url_bp);
                window.open(url_google, '_blank');
            });
        }
    };

    const downloadClick = (documento) => {
        App.obter_token_publico(function(token_publico) {
            App.download(window.WS_URI + 'documento/download/?documento_revisao_id=' + documento.documento_revisao_id + '&token_publico=' + token_publico);
        });
    }

    const documentos = props.documentos.map((documento) => {
        
        const visualizavel = (documento.mime_type === 'application/pdf'
                                || documento.mime_type === 'image/png' || documento.mime_type === 'image/bmp' || documento.mime_type === 'image/jpeg' || documento.mime_type === 'image/jpg' || documento.mime_type === 'image/gif'
                                || window.MIME_TYPE_OFFICE.indexOf(documento.mime_type) >= 0);
        return (
            <div style={{display: "inline-flex"}}>
            {visualizavel ? 
                <React.Fragment>
                    <Button type="normal" stylingMode="outlined" text={window.FileShortName(documento.revisao_nome)} onClick={() => visualizarClick(documento)}/>
                    <Button type="normal" stylingMode="outlined" icon="fa fa-download" hint="Download" onClick={() => downloadClick(documento)}/>
                </React.Fragment>
                :
                <Button type="normal" stylingMode="outlined" text={window.FileShortName(documento.revisao_nome)} onClick={() => downloadClick(documento)}/>
            }
            <HorizontalSpacer/>
            </div>
        );
    });
    return documentos;
}