
import SelecaoPadrao from './SelecaoPadrao';
import { usuarioColunas, listarUsuarios } from '../datasource/Usuario';

export default class SelecaoUsuario extends SelecaoPadrao {
    constructor(props) {
        super(props);
        this.state = {...this.state, ...this.propsToObjState()};
        this.displayExpr = this.displayExpr.bind(this);
    }

    propsToObjState() {
        return {
            selecionado: this.props.selecionado ? [this.props.selecionado] : null,
            readOnly: this.props.readOnly ?? null,
            displayExpr: this.displayExpr
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selecionado !== this.props.selecionado
            || prevProps.readOnly !== this.props.readOnly) {
            this.setState(this.propsToObjState());
        }
    }

    displayExpr = (item) => {
        return this.props.limparAoAlterar ? "" : (item ? item.nome : "");
    }
}

SelecaoUsuario.defaultProps = {
    placeholder: "Pesquisar por nome",
    aoAlterar: null,
    dataSource: listarUsuarios({
        equipe_interna: true,
        clientes: true,
        fornecedores: true,
        prospects: true,
        dx_grid: true
    }),
    limparAoAlterar: false,
    columns: usuarioColunas
};